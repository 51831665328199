.hero-text-block {
  &--headline {
    font-size: 65px;

    @media #{$sm-layout} {
      font-size: 32px;
    }
  }

  &--text {
    font-size: 25px;
    line-height: 32px;
  }
}

.gsap-marker-end, .gsap-marker-start, .gsap-marker-scroller-end, .gsap-marker-scroller-start {
  opacity: 0 !important;
  display: none !important;
  position: absolute;
}