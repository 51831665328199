.battery-hero-container {
  .content {
    position: absolute;
    z-index: 998; // 999 is header (menu, etc.)
    width: 100vw;
    height: 100vh;

    h1, h2, h3, h4, h5, h6, span, p {
      position: fixed;
      text-align: center;
      width: 100%;
      max-width: 1000px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      padding: 0 30px;

      @media #{$sm-layout} {
        font-size: 23px;
      }

      @media #{$md-layout} {
        font-size: 25px;
      }
    }
  }

  canvas {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: auto;
  }

  #initial-title {
    opacity: 1;

    @media #{$sm-layout} {
      font-size: 44px;
    }

    @media #{$md-layout} {
      font-size: 44px;
    }
  }

  #label-scroll-down {
    font-size: 20px;

    svg {
      margin: 40px 0 0 40px;
      animation: bounce 2s infinite;

      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translate(-50%, -50%);
        }
        40% {
          transform: translate(-50%, calc(-50% - 15px));
        }
        60% {
          transform: translate(-50%, calc(-50% - 7px));
        }
      }
    }
  }
}

.after-hero {
  position: relative;

  .intro-subtitle {
    color: #c6c9d8;
    font-weight: 300;
    opacity: 0.75;
    line-height: 2.25rem;
  }

  .about-area {
    p, p.description {
      color: #c6c9d8;
      opacity: .75;
      font-weight: 300;
    }

    .title {
      color: #c6c9d8;
    }
  }

  .rn-testimonial-content {
    .inner p, .author-info h6 span {
      color: #c6c9d8;
      opacity: .75;

      &::before, &::after {
        opacity: 0.03;
      }
    }

    .author-info h6 span {
      font-weight: bold;
    }
  }

  .slide {
    .intro-title {
      height: 100vh;
    }

    @media #{$sm-layout} {
      .title.intro-title {
        font-size: 35px;
        line-height: 45px;
      }
    }
  }
}