.icon-cards-hero-container {
  background: #020202;
  padding-bottom: 300px;

  @media #{$large-mobile} {
    padding-bottom: 100px;
  }

  .service-wrapper {
    .row > * {
      padding-top: 15px;
      padding-bottom: 15px;

      @media #{$large-mobile} {
        margin-top: 0;
        margin-bottom: 0;

        h1, h2, h3 {
          font-size: 32px;
        }
      }

      @media #{$md-layout} {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .img-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 9px;
  }
}