.parallax-gradient-hero-container {
  padding: 300px 0;

  .upper {
    position: relative;
    height: 1750px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-image: url(/assets/images/gradients/gradient-1.jpg);
    background-color: #020202;
  }

  .lower {
    position: relative;
    height: 1750px;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-image: url(/assets/images/gradients/gradient-1.jpg);
    transform: rotate(180deg);
    background-color: #020202;
  }

  .content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 99;

    .title {
      text-align: center;
      font-size: 80px;
      line-height: 90px;
      color: #020202;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 900;
      width: 85vw;

      @media #{$sm-layout} {
        font-size: 37px;
        line-height: 40px;
      }

      @media #{$md-layout} {
        font-size: 42px;
        line-height: 45px;
      }
    }

    .sub-title {
      text-align: center;
      color: #3ac0a5;
      font-family: "Poppins", sans-serif;
      font-size: 26px;
    }
  }
}