.gradient-masked-text-hero-container {
  width: 100%;
  height: 100vh;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    background: #020202;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .container {
      width: 100%;
      height: 100%;
      display: flex;
      background: #020202;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .text {
        color: #fff;
        position: relative;
        text-align: center;

        @media #{$sm-layout} {
          font-size: 26px;
          line-height: 32px;
        }

        @media #{$md-layout} {
          font-size: 30px;
          line-height: 38px;
        }

        .gradient {
          position: absolute;
          width: 100%;
          z-index: 2;
          background-color: #020202;
          mix-blend-mode: darken;
          top: 0;
          left: 0;
        }

        img {
          width: 100%;
          //object-fit: cover;

          @media #{$sm-layout} {
            height: 100%;
            max-width: unset;
            width: unset;
          }
        }
      }
    }
  }
}