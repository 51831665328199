.info-cards-hero-container {
  background: #020202;
  padding: 25vh 0;

  .slick-dots {
    z-index: 10;

    li.slick-active button:before {
      opacity: 1;
      color: #fff;
    }

    li button:before {
      color: #fff;
    }
  }
}