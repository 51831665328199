/* montserrat-regular - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-italic - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-v25-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-800 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-800.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-v25-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-800.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-v25-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-900 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-900.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat/montserrat-v25-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat/montserrat-v25-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-900.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat/montserrat-v25-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat/montserrat-v25-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* poppins-300 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/poppins/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/poppins/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/poppins/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/poppins/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/poppins/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/poppins/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/poppins/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/poppins/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/poppins/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/poppins/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/poppins/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/poppins/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/poppins/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/poppins/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/poppins/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/poppins/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/poppins/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/poppins/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/poppins/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/poppins/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700italic - latin */
@font-face {
  font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/poppins/poppins-v20-latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/poppins/poppins-v20-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/poppins/poppins-v20-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/poppins/poppins-v20-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/poppins/poppins-v20-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
}